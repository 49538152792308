.picker-dialog {
    z-index: 99999 !important;
}

.googleLogout,
.googleLogout * {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
    color: #ddd;
    font-family: Krub;
    font-size: 1rem;
}

.hiddenLogin {
    display: none !important;
}

.visibleLogin {
    width: 80% !important;
    background: #eee !important;
    border: none !important;
    box-shadow: none !important;
    height: 50px !important;
    outline: none !important;
    border-radius: 5px !important;
    display: flex;
    justify-content: center;
    font-size: 1rem !important;
}

.visibleLogin:hover {
    cursor: pointer;
}